import { AxiosPromise } from "axios";
import { baseApi } from "@utils/apiService";
import CONSTANTS from "@constants/index";
import { CompanyAddressType, ContactAddressType } from "@types";
import {
  useQuery,
  QueryKey,
  UseQueryResult,
  useMutation,
  useQueryClient,
} from "react-query";
import { toast } from "react-toastify";

const { API } = CONSTANTS;

export const fetchAddresses = ({
  queryKey,
}: {
  queryKey: QueryKey;
}): AxiosPromise<{ items: ContactAddressType[] }> =>
  baseApi
    .get(`${API.COMPANIES}/${queryKey[1]}/addresses`)
    .then((res) => res.data);

export const useAddresses = (
  companyId: string
): UseQueryResult<{ items: CompanyAddressType[] }> =>
  useQuery(["addresses", companyId], fetchAddresses, {
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    retry: 2,
  });

type UpdateAddressProps = {
  companyId: string;
  formData: Omit<CompanyAddressType, "id" | "created_at">;
  addressId?: string;
};

export const useCreateAddress = (successMsg: string, errorMsg: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: UpdateAddressProps) =>
      baseApi
        .post(`${API.COMPANIES}/${data.companyId}/addresses`, data.formData)
        .then((res) => res.data),
    onSuccess: async () => {
      queryClient.invalidateQueries(["addresses"]);
      toast.success(successMsg);
    },
    onError: () => {
      toast.error(errorMsg);
    },
  });
};

export const useUpdateAddress = (successMsg: string, errorMsg: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: UpdateAddressProps) =>
      baseApi
        .patch(
          `${API.COMPANIES}/${data.companyId}/addresses/${data.addressId}`,
          data.formData
        )
        .then((res) => res.data),
    onSuccess: async () => {
      queryClient.invalidateQueries(["addresses"]);
      toast.success(successMsg);
    },
    onError: () => {
      toast.error(errorMsg);
    },
  });
};

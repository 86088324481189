import React, { useCallback, useContext, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import GiftPlaceholder from "@assets/icons/gift_placeholder.png";
import {
  HeaderTopStyled,
  SubHeading,
  GiftsStyled,
  Container,
  GiftsContainer,
  GiftsFilter,
  GiftsContent,
  SelectedFilterBadge,
} from "../styled";
import { Gift } from "../Gift";
import { AuthContext } from "@providers/AuthProvider";
import {
  getGiftDescriptionByLanguageCode,
  getGiftPriceByCountryCode,
} from "@utils/common";
import { useGifts } from "../../hooks";
import { Error, Loader, Pagination, Radio } from "@components/common";
import { NoGifts } from "../NoGifts";
import { GiftPromo } from "../GiftPromo";
import { useGiftsFilter } from "../../../../api/gifts";
import CategoryBanner from "./CategoryBanner";
import CloseIcon from "@assets/icons/remove.svg";
import dayjs from "dayjs";

export const AllGifts: React.FC = () => {
  const { user } = useContext(AuthContext);
  const [page, setPage] = useState(1);
  const intl = useIntl();

  const [giftCategory, setGiftCategory] = useState<string>("any");

  const {
    data: gifts,
    isError,
    isLoading,
  } = useGifts(user.company_id, {
    origin_type: "boxday_merchant",
    is_company_specific: false,
    page,
    ...(giftCategory &&
      giftCategory !== "any" && {
        category_id: giftCategory,
      }),
  });

  const { data: filter } = useGiftsFilter(user.company_id);

  const selectedFilterItem = useMemo(
    () => filter?.items?.find((f) => f.id === giftCategory),
    [filter, giftCategory]
  );

  const handleGiftCategoryChange = useCallback(
    (e: any) => {
      setGiftCategory(e.target.value);
    },
    [setGiftCategory]
  );

  if (isError) {
    return <Error />;
  }

  return (
    <Container>
      <HeaderTopStyled>
        <SubHeading variant='h3' style={{ minWidth: 260, minHeight: 32 }}>
          <FormattedMessage id='allGifts.filter.title' />
        </SubHeading>
        {selectedFilterItem && (
          <SelectedFilterBadge>
            {dayjs(selectedFilterItem.date || new Date()).format("D.MM")}{" "}
            {
              selectedFilterItem.short_names?.find(
                (item) => item.language_code === user.locale
              )?.text
            }
            <button type='button' onClick={() => setGiftCategory("any")}>
              <CloseIcon />
            </button>
          </SelectedFilterBadge>
        )}
      </HeaderTopStyled>
      <GiftsContainer>
        {filter?.items && (
          <GiftsFilter>
            <SubHeading variant='h6'>
              <FormattedMessage id='allGifts.filter.occasion' />
            </SubHeading>
            <div>
              {
                <Radio
                  hooked={false}
                  name='gift-filter'
                  value={giftCategory}
                  onChange={handleGiftCategoryChange}
                  items={[
                    {
                      label: intl.formatMessage({
                        id: "allGifts.filter.occasion.any",
                      }),
                      value: "any",
                    },
                    ...(filter?.items?.map((category) => ({
                      label: `${dayjs(category.date || new Date()).format(
                        "D.MM"
                      )} ${
                        category.short_names?.find(
                          (item) => item.language_code === user.locale
                        )?.text
                      }`,
                      value: category.id,
                    })) ?? []),
                  ]}
                />
              }
            </div>
          </GiftsFilter>
        )}
        {isLoading ? (
          <Loader />
        ) : (
          <GiftsContent>
            {selectedFilterItem && (
              <CategoryBanner
                img={selectedFilterItem.image_url}
                color={selectedFilterItem.background_color_hex}
                description={
                  selectedFilterItem.descriptions?.find(
                    (d) => d.language_code === user.locale
                  )?.text || ""
                }
              />
            )}
            <GiftsStyled>
              {gifts.items?.map((gift, index) => (
                <Gift
                  key={gift.id + index}
                  id={gift.id}
                  title={gift.name}
                  status={gift.availability_state}
                  badges={gift.flags}
                  imgSrc={gift.images[0]?.url || GiftPlaceholder}
                  price={getGiftPriceByCountryCode(gift.prices, user.currency)}
                  description={getGiftDescriptionByLanguageCode(
                    gift.descriptions,
                    user.locale
                  )}
                  href={`/gifts/all/merchant/${gift.merchant.id}/${gift.id}`}
                  comingSoon={gift.availability_state === "coming_soon"}
                />
              ))}
              {gifts.items?.length === 0 && (
                <NoGifts headingId='gifts.list.empty.heading' />
              )}
              <GiftPromo />
            </GiftsStyled>
          </GiftsContent>
        )}
      </GiftsContainer>
      <Pagination
        page={page}
        onChange={setPage}
        total={gifts?.paging?.total_records || 0}
      />
    </Container>
  );
};

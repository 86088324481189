import React, { useContext, useState } from "react";
import { Box } from "@material-ui/core";
import {
  Button,
  Modal,
  DatePicker,
  Checkbox,
  GiftButton,
  Loader,
} from "@components/common";
import { FormattedMessage, useIntl } from "react-intl";
import { useForm, FormProvider } from "react-hook-form";
import { ContactType } from "@types";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import { DEFAULT_DATE_FORMAT_SHORT } from "@constants/common";
import { AuthContext } from "@providers/AuthProvider";
import { useGifts } from "../../../../gifts/hooks";
import { useCreateGiftDelivery } from "../../../hooks";
import { DialogFooterStyled } from "../../../../modal/components/Modal/components/styled";
import * as S from "./styled";

interface AssignExternalGiftModalProps {
  contact: ContactType;
  hideModal: () => void;
}

export const AssignExternalGiftModal: React.FC<
  AssignExternalGiftModalProps
> = ({ hideModal, contact }) => {
  const methods = useForm();
  const intl = useIntl();
  const {
    handleSubmit,
    formState: { errors, isDirty },
    watch,
  } = methods;
  const [selectedGift, setSelectedGift] = useState(null);
  const { user } = useContext(AuthContext);

  const { data: gifts, isLoading } = useGifts(user.company_id, {
    origin_type: "company",
  });
  const {
    mutate: createGiftDelivery,
    isSuccess,
    isError,
    isLoading: createLoading,
  } = useCreateGiftDelivery();

  const onSubmit = ({ datepicker }: { datepicker: string }) => {
    if (!isDirty) {
      toast.success(
        intl.formatMessage({
          id: "general.notifications.success",
        })
      );
      hideModal();
      return;
    }
    createGiftDelivery({
      contact_id: contact.id,
      company_id: user.company_id,
      data: {
        gift_id: selectedGift.id,
        delivered_at: datepicker,
        signature_external_id: "0",
      },
    });
  };

  if (isSuccess) {
    toast.success(
      intl.formatMessage({
        id: "general.notifications.success",
      })
    );
    hideModal();
  }

  if (isError) {
    toast.error(
      intl.formatMessage({
        id: "general.notifications.error",
      })
    );
    hideModal();
  }

  return (
    <Modal
      show
      title={intl.formatMessage(
        {
          id: "contacts.main.assignExternalGiftTo",
        },
        {
          name: `${contact.first_name} ${contact.last_name}`,
        }
      )}
      handleClose={hideModal}
      maxWidth='sm'
    >
      <Box display='flex' flexDirection='column'>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <S.FormSection>
              <S.FormContainer>
                {isLoading ? (
                  <Loader />
                ) : (
                  <S.GiftsList>
                    {gifts?.items?.map((gift) => (
                      <GiftButton
                        key={gift.id}
                        gift={gift}
                        onClick={setSelectedGift}
                        selected={selectedGift?.id === gift.id}
                        currency={user.currency}
                      />
                    ))}
                  </S.GiftsList>
                )}
              </S.FormContainer>
            </S.FormSection>

            <S.FormSection>
              <S.FormContainer>
                <DatePicker
                  name='datepicker'
                  disablePast={false}
                  label={intl.formatMessage({
                    id: "contacts.main.assignExternalGift.datepicker",
                  })}
                  required
                  direction='row'
                  error={errors.datepicker}
                  hooked
                />
                <Checkbox
                  name='accept'
                  required
                  error={errors.accept}
                  label={intl.formatMessage(
                    {
                      id: "contacts.main.assignExternalGiftConfirm",
                    },
                    {
                      date: dayjs(watch("datepicker")).format(
                        DEFAULT_DATE_FORMAT_SHORT
                      ),
                      name: `${contact.first_name} ${contact.last_name}`,
                    }
                  )}
                />
              </S.FormContainer>
            </S.FormSection>
            <DialogFooterStyled>
              <Button onClick={handleSubmit(onSubmit)} disabled={createLoading || !selectedGift}>
                <FormattedMessage id='contacts.main.assignExternalGift.submit' />
              </Button>
              <Button color='secondary' variant='text' onClick={hideModal}>
                <FormattedMessage id='general.cancel' />
              </Button>
            </DialogFooterStyled>
          </form>
        </FormProvider>
      </Box>
    </Modal>
  );
};

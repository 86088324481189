import styled from "styled-components";

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const FormSection = styled.div`
  padding: 24px;
  margin: -24px -24px 0;

  ul {
    max-height: 30vh;
    overflow-y: auto;
    padding: 0;
    margin: 0;
  }

  & + & {
    border-top: 1px solid #ececec;
    margin-top: 0;
  }
`;

export const GiftsList = styled.ul`
  max-height: 30vh;
  overflow-y: auto;
  padding: 0;
  margin: 0;
`;

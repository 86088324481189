import {
  AutomationType,
  CompanyAddressType,
  ContactType,
  TagType,
} from "@types";
import { GiftType } from "../../gifts/types";
import { useAutomations } from "../hooks";
import { useGifts } from "../../gifts/hooks";
import { useAllTags, useContacts } from "../../contacts/hooks";
import { useAddresses } from "../../account/components/Addresses/hooks";

type UserPrefetchData = {
  automations: AutomationType[];
  gifts: GiftType[];
  tags: TagType[];
  offices: CompanyAddressType[];
  contacts: ContactType[];
  isError: boolean;
  isLoading: boolean;
};

export const usePrefetchCreateOrderData = (
  companyId: string
): UserPrefetchData => {
  const {
    data: automations,
    isLoading: loading1,
    isError: error1,
  } = useAutomations(companyId, "upcoming", 1);
  const {
    data: gifts,
    isLoading: loading2,
    isError: error2,
  } = useGifts(companyId, { origin_type: "boxday_merchant" });
  const {
    data: tags,
    isLoading: loading3,
    isError: error3,
  } = useAllTags(companyId);
  const {
    data: offices,
    isLoading: loading4,
    isError: error4,
  } = useAddresses(companyId);
  const {
    data: contacts,
    isLoading: loading5,
    isError: error5,
  } = useContacts(companyId, 1);

  return {
    automations: automations?.items,
    gifts: gifts?.items,
    tags,
    offices: offices?.items,
    contacts: contacts?.items,
    isError: error1 || error2 || error3 || error4 || error5,
    isLoading: loading1 || loading2 || loading3 || loading4 || loading5,
  };
};

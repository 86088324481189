import React, { useContext, useEffect, useMemo } from "react";
import { Box, InputAdornment, Typography } from "@material-ui/core";
import { Button, Modal, InputFile, TextField } from "@components/common";
import { FormattedMessage, useIntl } from "react-intl";
import { useForm, FormProvider } from "react-hook-form";
import { GiftType } from "@types";
import { toast } from "react-toastify";
import { AuthContext } from "@providers/AuthProvider";
import { useCreateGift, useUpdateGift } from "./hooks";
import { DialogFooterStyled } from "../../../../modal/components/Modal/components/styled";
import { InputRow } from "./styled";
import { getGiftDescriptionByLanguageCode } from "@utils/common";

interface AddGiftModalProps {
  isEdit: boolean;
  gift?: GiftType;
  hideModal: () => void;
}

export const AddExternalGiftModal: React.FC<AddGiftModalProps> = ({
  isEdit,
  hideModal,
  gift,
}) => {
  const { user } = useContext(AuthContext);

  const methods = useForm({
    defaultValues: useMemo(
      () => ({
        title: gift?.name || "",
        description: getGiftDescriptionByLanguageCode(
          gift?.descriptions,
          user.locale
        ),
        price: gift?.prices?.find(
          (price) => price.country_code === user.currency
        )?.amount,
        total:
          gift?.prices?.find((price) => price.country_code === user.currency)
            ?.amount +
          gift?.prices?.find((price) => price.country_code === user.currency)
            ?.vat,
        vat: gift?.prices?.find((price) => price.country_code === user.currency)
          ?.vat,
        image: "",
      }),
      [gift]
    ),
  });
  const intl = useIntl();

  const {
    handleSubmit,
    formState: { errors, isDirty },
    setValue,
    watch,
  } = methods;
  const {
    mutate: createGift,
    isSuccess: isCreated,
    isError: error1,
    isLoading: loading1,
  } = useCreateGift();
  const {
    mutate: updateGift,
    isSuccess: isUpdated,
    isError: error2,
    isLoading: loading2,
  } = useUpdateGift();

  const onSubmit = ({
    title,
    description,
    price,
    image,
    vat,
  }: {
    title: string;
    description: string;
    price: string;
    image: File;
    vat: string;
  }) => {
    if (!isDirty) {
      toast.success(
        intl.formatMessage({
          id: "general.notifications.success",
        })
      );
      hideModal();
      return;
    }
    if (isEdit) {
      updateGift({
        company_id: user.company_id,
        gift_id: gift.id,
        data: {
          name: title,
          descriptions: [
            {
              text: description,
              language_code: "sk",
            },
          ],
          prices: [
            {
              amount: Number(price),
              vat: Number(vat),
              currency: "EUR",
              country_code: "sk",
            },
          ],
          is_external: true,
          flags: {
            has_handwritten_note: false,
            is_branded: false,
          },
          availability_state: "in_stock",
        },
        image_ids: gift.images?.[0]?.id ? [gift.images?.[0].id] : [],
        images: image ? [image] : [],
      });
    } else {
      createGift({
        company_id: user.company_id,
        data: {
          name: title,
          descriptions: [
            {
              text: description,
              language_code: "sk",
            },
          ],
          prices: [
            {
              amount: Number(price),
              vat: Number(vat),
              currency: "EUR",
              country_code: "sk",
            },
          ],
          is_external: true,
          flags: {
            has_handwritten_note: false,
            is_branded: false,
          },
          availability_state: "in_stock",
        },
        images: image ? [image] : [],
      });
    }
  };

  if (isCreated || isUpdated) {
    toast.success(
      intl.formatMessage({
        id: "general.notifications.success",
      })
    );
    hideModal();
  }

  if (error1 || error2) {
    toast.error(
      intl.formatMessage({
        id: "general.notifications.error",
      })
    );
    hideModal();
  }

  useEffect(() => {
    if (watch("price") && watch("vat")) {
      setValue(
        "total",
        (Number(watch("price")) + Number(watch("vat"))).toFixed(2)
      );
    }
  }, [watch("price"), watch("vat"), watch, setValue]);

  return (
    <Modal
      show
      title={intl.formatMessage({
        id: "externalGifts.add.title",
      })}
      handleClose={hideModal}
      maxWidth='xs'
    >
      <Box display='flex' flexDirection='column'>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <TextField
              name='title'
              placeholder={intl.formatMessage({
                id: "externalGifts.add.input.title",
              })}
              label={intl.formatMessage({
                id: "externalGifts.add.input.title",
              })}
              error={errors.title}
              required
            />
            <TextField
              name='description'
              type='text'
              multiline
              minRows={4}
              placeholder={intl.formatMessage({
                id: "externalGifts.add.input.description",
              })}
              label={intl.formatMessage({
                id: "externalGifts.add.input.description",
              })}
              error={errors.description}
              required
            />
            <InputFile
              name='image'
              label={intl.formatMessage({
                id: "externalGifts.add.input.image",
              })}
              accept='.png,.jpg,.jpeg'
              error={errors.image}
              // required={!isEdit}
              file={null}
              defaultView
            />
            <InputRow>
              <TextField
                name='price'
                placeholder='0.00'
                label={intl.formatMessage({
                  id: "externalGifts.add.input.price",
                })}
                error={errors.price}
                required
                type='number'
                startAdornment={
                  <InputAdornment position='start'>€</InputAdornment>
                }
              />
              <TextField
                name='vat'
                placeholder='0.00'
                label={intl.formatMessage({
                  id: "externalGifts.add.input.vat",
                })}
                error={errors.vat}
                startAdornment={
                  <InputAdornment position='start'>€</InputAdornment>
                }
                type='number'
                required
              />
              <TextField
                name='total'
                placeholder='0.00'
                label={intl.formatMessage({
                  id: "externalGifts.add.input.total",
                })}
                error={errors.total}
                startAdornment={
                  <InputAdornment position='start'>€</InputAdornment>
                }
                type='number'
                disabled
                required
              />
            </InputRow>
            {isEdit && (
              <Typography variant='body1' color='textSecondary'>
                <FormattedMessage id='externalGifts.edit.info' />
              </Typography>
            )}
            <DialogFooterStyled>
              <Button
                disabled={loading1 || loading2}
                onClick={handleSubmit(onSubmit)}
              >
                <FormattedMessage id='general.save' />
              </Button>
              <Button
                disabled={loading1 || loading2}
                color='secondary'
                variant='text'
                onClick={hideModal}
              >
                <FormattedMessage id='general.cancel' />
              </Button>
            </DialogFooterStyled>
          </form>
        </FormProvider>
      </Box>
    </Modal>
  );
};

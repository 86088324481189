import { IconButton } from "@material-ui/core";
import styled from "styled-components";

export const GiftEditButton = styled(IconButton)`
  position: absolute;
  right: 8px;
  top: 8px;
  border-radius: 50%;
  background-color: #fff;
  padding: 8px;
  visibility: hidden;
  opacity: 0;
  z-index: 100;

  &:hover,
  &:focus {
    background-color: #fff;
  }

  svg {
    width: 16px;
  }
`;

export const GiftWrapper = styled.div`
  position: relative;
  &:hover {
    ${GiftEditButton} {
      visibility: visible;
      opacity: 1;
    }
  }
`;

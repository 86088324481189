import React, { useContext } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Button, Loader, Pagination } from "@components/common";
import { toast } from "react-toastify";
import {
  getGiftDescriptionByLanguageCode,
  getGiftPriceByCountryCode,
} from "@utils/common";
import EditIcon from "@assets/icons/edit.svg";
import { GiftType } from "@types";
import { AuthContext } from "@providers/AuthProvider";
import GiftPlaceholder from "@assets/icons/gift_placeholder.png";
import { HeaderTopStyled, SubHeading, Container, GiftsStyled } from "../styled";
import { AddExternalGiftModal } from "./AddGiftModal";
import { Gift } from "../Gift";
import { useGifts } from "../../hooks";
import { NoGifts } from "../NoGifts";
import * as S from "./styled";

export const ExternalGifts = () => {
  const intl = useIntl();
  const [showAddModal, setShowAddModal] = React.useState(false);
  const [editGift, setEditGift] = React.useState<GiftType>(null);
  const { user } = useContext(AuthContext);
  const [page, setPage] = React.useState(1);

  const { data: gifts, status } = useGifts(user.company_id, {
    origin_type: "company",
    page,
  });

  if (status === "error") {
    toast.error(
      intl.formatMessage({
        id: "general.notifications.error",
      })
    );
  }

  return (
    <Container>
      <HeaderTopStyled>
        <SubHeading variant='h3'>
          <FormattedMessage id='externalGifts.title' />
        </SubHeading>
        <Button
          variant='text'
          color='primary'
          onClick={() => setShowAddModal(true)}
        >
          <FormattedMessage id='externalGifts.addTitle' />
        </Button>
        {showAddModal && (
          <AddExternalGiftModal
            isEdit={!!editGift}
            hideModal={() => {
              setShowAddModal(false);
              setEditGift(null);
            }}
            gift={editGift}
          />
        )}
      </HeaderTopStyled>
      {status === "loading" ? (
        <Loader />
      ) : (
        <GiftsStyled>
          {gifts?.items?.map((gift) => (
            <S.GiftWrapper key={gift.id}>
              <Gift
                id={gift.id}
                key={gift.id}
                imgSrc={gift.images[0]?.url || GiftPlaceholder}
                title={gift.name}
                description={getGiftDescriptionByLanguageCode(
                  gift.descriptions,
                  user.locale
                )}
                price={getGiftPriceByCountryCode(gift.prices, user.currency)}
                badges={{
                  has_handwritten_note: false,
                  is_branded: false,
                }}
                status='external'
                external
                href={`/gifts/external/company/${user.company_id}/${gift.id}`}
                comingSoon={false}
              />
              <S.GiftEditButton
                onClick={() => {
                  setEditGift(gift);
                  setShowAddModal(true);
                }}
                disableRipple
                size='small'
                color='secondary'
              >
                <EditIcon />
              </S.GiftEditButton>
            </S.GiftWrapper>
          ))}
        </GiftsStyled>
      )}
      {gifts?.items?.length === 0 && (
        <NoGifts
          headingId='externalGifts.list.empty.heading'
          messageId='externalGifts.list.empty.message'
        />
      )}
      <Pagination
        page={page}
        onChange={setPage}
        total={gifts?.paging?.total_records || 0}
      />
    </Container>
  );
};

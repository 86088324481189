import { baseApi } from "@utils/apiService";
import { useMutation, useQueryClient } from "react-query";
import { GiftType } from "@types";
import { GiftCreate } from "../../../types";

export const useCreateGift = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      company_id,
      data,
      images,
    }: {
      company_id: string;
      data: GiftCreate;
      images?: File[];
    }) =>
      baseApi
        .post(`/api/companies/${company_id}/gifts`, data)
        .then(({ data: response }: { data: GiftType }) =>
          Promise.all(
            images.map((image) =>
              baseApi.post(
                `/api/gifts/${response.id}/images`,
                { file: image },
                {
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
                }
              )
            )
          ).then((res) => res.data)
        ),
    onSuccess: async () => {
      queryClient.invalidateQueries(["gifts"]);
    },
  });
};

export const useUpdateGift = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({
      company_id,
      gift_id,
      data,
      image_ids,
      images,
    }: {
      company_id: string;
      gift_id: string;
      data: GiftCreate;
      image_ids?: string[];
      images: File[];
    }) => {
      if (images?.length) {
        return baseApi
          .patch(`/api/companies/${company_id}/gifts/${gift_id}`, data)
          .then((res) => res.data)
          .then(() =>
            Promise.all(
              image_ids.map((image_id) =>
                baseApi.delete(`/api/gifts/${gift_id}/images/${image_id}`)
              )
            )
              .then(() =>
                Promise.all(
                  images.map((image) =>
                    baseApi.post(
                      `/api/gifts/${gift_id}/images`,
                      { file: image },
                      {
                        headers: {
                          "Content-Type": "multipart/form-data",
                        },
                      }
                    )
                  )
                )
              )
              .then((res) => res.data)
          );
      }
      return baseApi
        .patch(`/api/companies/${company_id}/gifts/${gift_id}`, data)
        .then((res) => res.data);
    },
    onSuccess: async () => {
      queryClient.invalidateQueries(["gifts"]);
    },
  });
};

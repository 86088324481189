import {
  getHighlightedText,
  formatMoney,
  getGiftPriceByCountryCode,
  termsAndCondText,
  filterOrdersByMonth,
  SHOW_SUPPORT_CHAT,
  getGiftDescriptionByLanguageCode,
  getGiftPriceWithoutVatByCountryCode,
  formatBoldTag,
  isWeekend,
  handleWeekends,
} from "@ui/src/utils/common";

export {
  getGiftDescriptionByLanguageCode,
  getGiftPriceWithoutVatByCountryCode,
  getHighlightedText,
  formatMoney,
  getGiftPriceByCountryCode,
  termsAndCondText,
  filterOrdersByMonth,
  SHOW_SUPPORT_CHAT,
  formatBoldTag,
  isWeekend,
  handleWeekends,
};

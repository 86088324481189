import React, { useEffect, useState } from "react";
import * as S from "./styled";
import ArrowRightIcon from "@assets/icons/arrow-right.svg";
import Modal from "../Modal";
import TextField from "../TextField";
import { FormattedMessage, useIntl } from "react-intl";
import { UserProfileType, CompanyType, PagingType } from "@types";
import Pagination from "../Pagination";
import Loader from "../LoaderCentered";

interface CompanySwitchModalProps {
  companies: CompanyType[];
  user: UserProfileType;
  cta: (id: string) => void;
  paging: PagingType;
  page: number;
  setPage?: () => void;
  showModal: boolean;
  setShowModal: (show: boolean) => void;
  isLoading: boolean;
  searchValue?: string;
  setSearchValue?: (value: string) => void;
  withSearch?: boolean;
}

export const CompanySwitchModal: React.FC<
  CompanySwitchModalProps & React.PropsWithChildren
> = ({
  companies,
  user,
  cta,
  paging,
  page,
  setPage,
  showModal,
  setShowModal,
  isLoading,
  searchValue,
  setSearchValue,
  withSearch = true,
}) => {
  const [selectedCompany] = useState<string>(user?.company_id);
  const [newCompany, setNewCompany] = useState<string>(null);

  const [loading, setLoading] = useState(false);
  const intl = useIntl();

  useEffect(() => {
    setNewCompany(companies?.find((c) => c.id !== user.company_id)?.id);
  }, [companies]);

  return (
    <Modal
      show={showModal}
      title={intl.formatMessage({
        id: "Choose company",
      })}
      ctaTitle={intl.formatMessage(
        {
          id: "Switch to",
        },
        {
          value: companies?.find((c) => c.id === newCompany)?.business_name,
        }
      )}
      cancelTitle={intl.formatMessage({
        id: "general.cancel",
      })}
      maxWidth='sm'
      cta={() => {
        setLoading(true);
        cta(newCompany);
      }}
      handleClose={() => {
        setShowModal(false);
        setShowModal(false);
      }}
      isLoading={isLoading || loading}
    >
      <>
        {withSearch && (
          <TextField
            value={searchValue}
            // @ts-expect-error
            onChange={(e) => setSearchValue(e.target.value)}
            hooked={false}
            placeholder={intl.formatMessage({ id: "Search companies" })}
          />
        )}
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <S.CompanyList>
              {companies?.length > 0 &&
                companies.map((company) => (
                  <li key={company.id}>
                    <S.CompanySwitchButton
                      disableRipple
                      disabled={company.id === selectedCompany}
                      onClick={() => setNewCompany(company.id)}
                      isActive={company.id === selectedCompany}
                      isSelected={company.id === newCompany}
                    >
                      <div>
                        <ArrowRightIcon />
                      </div>
                      {company.business_name}
                      {company.id === selectedCompany && (
                        <FormattedMessage id='Active' />
                      )}
                      <span>{company.notification_email}</span>
                    </S.CompanySwitchButton>
                  </li>
                ))}
            </S.CompanyList>
            <Pagination
              total={paging?.total_records}
              page={page}
              onChange={setPage}
            />
          </>
        )}
      </>
    </Modal>
  );
};
